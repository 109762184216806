<script>
import CommonLayout from "@/views/CommonLayout.vue";

export default {
  name: "BookingPolicy",
  components: {
    CommonLayout
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="policy-content">
        <div class="heading-container animate__animated animate__fadeInDown">
          <h1 class="page-title">Privacy & Cookies Policy</h1>
        </div>

        <div class="column animate__animated animate__fadeIn animate__slow">
          <h5>Our Privacy Policy</h5>
          <br />

          <p>
            Explore the Local ('ETL', 'we', 'us', 'our') are committed to protecting and respecting
            your personal data and privacy. We are committed to doing all we can to comply with data
            protection principles and provisions under applicable laws.
          </p>

          <p>
            We may collect, process, use and disclose your information when you use the website
            www.explorethelocal.com (‘the ETL website’) and the services offered by ETL or third
            party operators ('ETL Operators') through the ETL website ('ETL Services') as described
            in this Privacy Policy. 'You' and 'your' when used in this Privacy Policy includes any
            person who accesses the ETL website or uses ETL Services.
          </p>

          <p>
            This Privacy Policy sets out the basis and terms upon which ETL collects, processes,
            uses and/or discloses your information that is obtained from you when you access the ETL
            website and/or use ETL Services. Such information may include personal information
            relating to or linked to a specific individual such as name, residential address,
            telephone number, email address, travel document information, insurance information,
            age, date of birth, or any such information we have requested and you have provided
            through the ETL website ('Personal Information').
          </p>

          <p class="bold">Please ensure you read and understand our Privacy Policy carefully.</p>

          By visiting the ETL website, you are consenting to the collection, processing, usage and
          disclosure of your Personal Information as set out in this Privacy Policy.<br /><br />

          <ul>
            <li>
              <p class="bulletpoint">Scope of Policy Terms</p>

              ETL reserves the right to update, amend or modify the terms of this Privacy Policy or
              any part of it without prior notice, and your continued access of the ETL website or
              use of ETL Services signifies your acceptance of the updated, amended or modified
              Privacy Policy, unless if the changes reduce your rights. In such instances, we will
              ask for your consent. If you do not agree to all the terms and conditions in this
              Privacy Policy and/or any subsequent updates, amendments or modifications thereto, you
              must stop accessing or otherwise using the ETL website and ETL Services.<br /><br />

              Accordingly, please visit this page if you wish to access and view the current version
              of this Privacy Policy.<br /><br />
            </li>

            <li>
              <p class="bulletpoint">Collection of Information</p>

              We may collect Personal Information about you that you provide to us while using the
              ETL website and information about how you use the ETL website including when you visit
              the ETL website or make reservations and/or bookings for any ETL Services. Providing
              your Personal Information to ETL is always on a voluntary basis. However, we might not
              be able to provide you with certain ETL Services if you choose not to give us your
              Personal Information. As an example, we cannot make reservations and/or bookings for
              you if we do not collect your name and contact details.<br /><br />

              <ol class="ordered-list1">
                <li>
                  Visit the ETL website to make Reservations and/or Bookings for ETL Services:<br /><br />

                  When you visit the ETL website and make reservations and/or bookings for any ETL
                  Services or use the Services, we may collect and process certain information
                  (which may contain your Personal Information or may contain non-personally
                  identifiable information but nevertheless linked to your Personal Information)
                  including but not limited to the information set out below:<br /><br />

                  <ol class="ordered-list2">
                    <li>
                      Copies of interactions (whether by e-mail, instant or personal messaging or
                      otherwise) between you and us, and between you and ETL Operators;
                    </li>
                    <br />

                    <li>
                      Information necessary to fulfil your reservations and/or bookings with ETL and
                      ETL Operators (including but not limited to travel document information,
                      insurance information, age, and date of birth);
                    </li>
                    <br />

                    <li>
                      Details of your usage of the ETL website (including traffic data, location
                      data and length of user sessions);
                    </li>
                    <br />

                    <li>
                      Feedback on and responses to surveys conducted by ETL relating to ETL Services
                      and newsletters which may be published, circulated or distributed by ETL;
                    </li>
                    <br />

                    <li>
                      Information automatically collected and stored in our server or the server of
                      our third party services providers by using or accessing the ETL website
                      (including, your computer’s Internet Protocol (IP) address, browser type,
                      browser information, device information (including unique device identifier),
                      pages visited, previous or subsequent sites visited).
                    </li>
                    <br />
                  </ol>
                </li>

                <li>
                  When you use your mobile devices to visit the ETL website, we may collect
                  information about you and your mobile device as stated above. This can include
                  location information if you have consented and configured your mobile device to
                  send us such information or upload photos with location information to the ETL
                  website. Such location information will be used in the ways and for the purposes
                  as stated in this Privacy Policy. For example, we may use your location to suggest
                  nearby Services during your travel or provide personalised content. You can change
                  your mobile device’s privacy settings at any time to deactivate the function of
                  sharing location information. Please note some features of the ETL website may be
                  affected if you turn off such location sharing function. If you require further
                  information about your mobile devices’ privacy settings, we recommend you contact
                  your mobile services provider or the manufacturer of your device.<br /><br />

                  If you make reservations, rentals, and/or bookings for other individuals through
                  the ETL website, we may request personal information about such individuals. You
                  must obtain all requisite consent of such individuals and ensure they are aware
                  of, understand and accept this Privacy Policy prior to providing their personal
                  data to ETL.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <p class="bulletpoint">Storage of Information</p>

              <p>
                The Personal Information and other data we collect from you may be transferred to,
                processed, and stored in our servers or the servers of our third party services
                providers. Your Personal Information will be stored as long as is necessary to
                fulfil any of the purposes described in this Privacy Policy, or to comply with any
                (i) contractual requirements that are necessary for the provision of the Services,
                and (ii) legal, tax or accounting requirements.
              </p>

              <p>
                When it is no longer necessary for us to process your Personal Information, we will
                either delete or anonymise the data or, if this is not possible (for example,
                because your personal information has been stored in backup archives), then we will
                securely store your personal information.
              </p>

              <p>
                We will endeavour to anonymise or aggregate your data if we intend to use it for
                analytical purposes or trend analysis.
              </p>

              <p>
                ETL will use reasonable endeavours to maintain appropriate physical, electronic and
                organisational procedures to ensure that your Personal Information and other data is
                treated securely and in accordance with this Privacy Policy, and to protect such
                data against unauthorized access or unauthorized alteration, disclosure or
                destruction.
              </p>

              <p>
                Once we have received your information, we will use strict procedures and security
                features to try to prevent unauthorized access. ETL does not give any
                representation, or undertaking that the Personal Information you provide to us will
                be secure at all times, and to the extent ETL has fulfilled its obligations under no
                circumstances shall ETL be responsible for any losses, damages, costs and expenses
                which you may suffer or incur arising from unauthorised access to or use of your
                Personal Information.
              </p>

              <p>
                All payment transactions carried out by us or our chosen third-party provider of
                payment processing services will be encrypted using online encryption technology.
              </p>
            </li>

            <li>
              <p class="bulletpoint">Usage of Information</p>

              <p>
                We process the Personal Information collected as far as necessary for performance of
                the contract with you and for providing services to you. Besides, we process the
                other Personal Information collected on the basis of our legitimate interests, which
                are the further improvement of our service and for direct marketing purposes.
              </p>

              <p>
                For example, ETL will use Personal Information and other data collected through the
                ETL website to fulfil your reservations and/or bookings for ETL Services, to provide
                you with ETL Services, to continually improve the ETL website and ETL Services, and
                to contact you in relation to ETL Services. This includes using your Personal
                Information or such other data to achieve faster purchase requests, better customer
                support and to provide you with timely notice of new Services and special offers.
              </p>

              <p>
                From time to time, we may also make use of your Personal Information to contact you
                for feedback on your use of the ETL website, to assist us in improving the ETL
                website, or to offer special savings or promotions to you, where you have indicated
                your consent to receiving such communications. If you would prefer not to receive
                correspondences outlining special savings or promotions, you may simply opt-out from
                receiving them by replying to us through the relevant means outlined in these
                correspondences.
              </p>
            </li>

            <li>
              <p class="bulletpoint">Disclosure of Information</p>

              <p>
                We may from time to time share and disclose your Personal Information and other data
                to third parties, some of whom may be located outside your home country. The
                circumstances under which such sharing and disclosure will take place may include
                without limitation, the following:
              </p>

              <ol class="ordered-list1">
                <li>
                  To successfully complete your reservations and/or bookings, we may share your
                  information with ETL Operators or third parties (including but not limited to tour
                  operators, activity providers, restaurants, shops, transportation companies,
                  railway companies, cruise companies, amusement parks, telecommunication network
                  operators, hotels, car rental companies, insurance companies, etc.), both within
                  and outside your home country, who deliver or provide goods and services or
                  otherwise act on our behalf.
                </li>
                <br />

                <li>
                  If you are a visitor, to the relevant ETL operators in connection with ETL
                  Services which you have made reservations and/or bookings for or intend to make
                  reservations and/or bookings for.
                </li>
                <br />

                <li>
                  For ETL operators, to any visitor in connection with the Services you are
                  offering.
                </li>
                <br />

                <li>
                  To our third party service providers (including Google Analytics and Amazon Web
                  Services), which we engage amongst others for the performance of certain services
                  on our behalf, such as web hosting services, data analysis, marketing, market
                  research, and to otherwise provide you with customer service.
                </li>
                <br />

                <li>
                  If and to the extent required by any applicable law, order of court or requests by
                  any governmental authority to make such disclosure.
                </li>
                <br />

                <li>
                  In case of a corporate transaction, in connection with the sale, merger,
                  acquisition, or other corporate reorganization or restructuring of our
                  corporation, your Personal Information may be disclosed, shared or transferred to
                  the new controlling entity or its authorised third party for carrying on our
                  business.
                </li>
                <br />

                <li>
                  To our advisors, agencies or other parties concerned in order to protect the
                  rights and property of ETL.
                </li>
                <br />

                <li>In any other case, to any third parties with your prior written consent</li>
                <br />
              </ol>

              <p>
                We may also share aggregate or anonymous information with relevant third parties,
                including our advertisers. Such information does not contain any Personal
                Information and will not identify you personally. However, in some occasions, these
                third parties may possess information about you or obtain your information from
                other sources. When they combine such information with our aggregate information,
                they may be able to identify you personally.
              </p>

              <p>
                Your Personal Information may be transferred outside of your home country and
                outside of the European Union, for the abovementioned purposes. If such transfer
                takes place to a country that does not provide an adequate level of protection, ETL
                will use reasonable endeavours to ensure that appropriate safeguards are in place.
                Such safeguards include but are not limited to (i) standard contractual clauses of
                the European Commission; (ii) the EU-US and Switzerland-US privacy shield; and/or
                (iii) any other appropriate cross-border transfer mechanisms.
              </p>

              <p>
                There may be links present on the ETL website which could result in you leaving the
                ETL website and/or being taken to other third party websites. You should note that
                any Personal Information that you provide to these third party websites are not
                subject to this Privacy Policy, and ETL is not liable for any losses, damages, costs
                or expenses which you may suffer or incur in connection with you providing or making
                available Personal Information or other data to such third party websites.
              </p>
            </li>

            <li>
              <p class="bulletpoint">Your rights</p>

              <p>
                You may at all times access, correct or erase your Personal Information through the
                ETL website by making your data access, correction or erasure request by sending
                your request by email to info@explorethelocal.com.
              </p>

              <p>
                Where mandatory under applicable legislation, you may also request restriction of
                processing of your Personal Information or object to processing by sending your
                request or objection by email to info@explorethelocal.com. You may also request a
                copy of the information that we hold about you by sending your request by email to
                info@explorethelocal.com.
              </p>

              <p>
                Please contact us via the contact details mentioned below if you have a complaint
                regarding the processing of your Personal Information.
              </p>

              <p>
                When handling any of these requests described above, we have the right to check the
                identity of the requester to ensure that he/she is the person entitled to make the
                request.
              </p>
            </li>

            <li>
              <p class="bulletpoint">Inquiries</p>

              <p>
                If you have any questions about this Privacy Policy, please contact us by email at
                info@explorethelocal.com.
              </p>
            </li>
          </ul>
          <br />

          <h5>Our Cookies Policy</h5>
          <br />

          <p>
            Cookies are widely used across the internet to make websites work, or to enable them to
            work more efficiently. When you visit our Website, we collect some of your Personal
            Information transmitted to us by your browser via cookies. This enables you to access
            the ETL website and helps us to create a better user experience for you.
          </p>

          <p>
            At ETL, we strive to enhance your experience when using the ETL website. In general, we
            use cookies to manage the ETL website and improve your overall site experience. Your
            data privacy and confidentiality are our top priority and we do our very best to
            safeguard the information you entrust to us.
          </p>

          <p>
            This section sets out the basis and terms upon which ETL uses cookies.
          </p>

          <p class="bold">What are cookies?</p>

          <p>
            Cookies are small text files stored in your browser that contain basic information about
            your internet use, such as login details and your previous browsing records.
          </p>

          <p>
            A cookie can be classified in terms of its (i) duration and (ii) the party who places
            it:
          </p>

          <ol class="ordered-list3">
            <li class="bold italic">
              Session cookies and Persistent cookies
            </li>
            <br />

            <p>
              The duration of cookies depends on whether they are session or persistent cookies.
            </p>

            <p><b class="bold">Session</b> cookies expire when your browsing session ends.</p>

            <p>
              <b class="bold">Persistent cookies</b> are saved on your computer or mobile device for
              a fixed period of time. When you visit a website, your browser will retrieve these
              cookies so that the website will recognise your device and personalise your site
              experience according to your previous browsing records.
            </p>

            <li class="bold italic">
              First-party cookies and Third-party cookies
            </li>
            <br />

            <p>
              We control and set the scope of <b class="bold">first-party cookies</b> whereas, we
              have no control over <b>third-party cookies</b>. Third-party cookies are operated by
              third-party sites in a different domain. Some pages of this Website contain
              third-party cookies in the form of embedded content, such as JavaScript and API.
            </p>

            <p class="bold italic">Why do we use cookies?</p>

            <p>
              We are committed to providing you with a safe, efficient and customised service. To
              reach this goal, we use cookies to:
            </p>

            <ul>
              <li>Ensure the well functioning of the ETL website;</li>
              <br />
              <li>Evaluate how the ETL website is accessed and used;</li>
              <br />
              <li>Analyse what destinations and experiences are popular among visitors;</li>
              <br />
              <li>Determine your eligibility for campaigns and promotions;</li>
              <br />
              <li>Recommend experiences that match your interests; and</li>
              <br />
              <li>Improve the content of the ETL website and enhance user's experience.</li>
              <br />
            </ul>

            <p>
              We will ensure that cookies will not be used for any purpose other than those
              described here.
            </p>

            <p class="bold italic">What types of cookies do we use?</p>

            <p>
              We use four types of cookies, namely "Critical cookies", "Essential cookies,"
              "Analytical cookies", and "Promotional cookies". Further information on these cookies
              is set out in the table below:
            </p>
          </ol>

          <table border="3" class="table">
            <tr>
              <th>Cookies</th>
              <th>Description</th>
            </tr>

            <tr>
              <td>Critical cookies</td>
              <td>
                <p>
                  These cookies allow us to manage the ETL website and ensure it functions properly.
                  They are essential to help you navigate and provide you with an improved browsing
                  experience. If these cookies are disabled, we will not be in a position to offer
                  you ETL services.
                </p>

                <p>
                  We also use these cookies to detect and prevent fraud. Further, thanks to these
                  cookies, you do not need to log in repeatedly to access different pages once you
                  have logged into this Website.
                </p>
              </td>
            </tr>

            <tr>
              <td>
                Analytical Cookies
              </td>

              <td>
                We use analytical cookies including Google Analytics and other analytics tools to
                gather anonymous statistical information for:
                <ul class="margin">
                  <li>assessing how visitors use the ETL website;</li>
                  <li>understand the demographic and location of our visitors;</li>
                  <li>
                    evaluate how our new products are being received by users, enablind us to assess
                    the popularity of our marketing campaign;
                  </li>
                  <li>
                    enhancing the ETL website content and service by customising to your needs.
                  </li>
                </ul>
                Based on the anonymous data collected, these analytical tools will generate trend
                analysis and compile reports to attain the abovementioned purposes.
              </td>
            </tr>

            <tr>
              <td>
                Promotional cookies
              </td>

              <td>
                Promotional cookies enable us to limit the number of times you see the same ad and
                to ensure that ad content is relevant to your interests. These cookies, together
                with an identifier linked to your IP address, can track your activity across various
                websites and thus display ads in line with your preference.
              </td>
            </tr>
          </table>

          <p class="bold italic">Consent to cookies</p>

          <p>
            In most cases we will need your consent to use cookies on this Website. The exception is
            where the cookies are strictly necessary to provide you with a service you have
            requested, or essential to the inherent functionality of the ETL website.
          </p>

          <p>
            Upon your first visit to the ETL website, you will see a notification banner at the top
            of the page notifying you about our use of cookies. By continuing to use this Website
            without changing your settings, you agree to receive all cookies and other data tracking
            tools. On the other hand, if you do not wish to accept the use of these cookies, you can
            modify your cookie setting at any time by emailing us at info@explorethelocal.com
          </p>

          <p class="bold italic">How can you manage your consent?</p>

          <p>
            You can accept or decline our use of cookies at any time by managing and/or disabling
            all or some of the cookies from your browser settings. However, by doing so, you may not
            be able to enjoy the full functionality of the ETL website.
          </p>

          <p>
            Cookies settings can normally be found in the preference or settings menu from your
            browser, for example:
          </p>

          <ul class="margin">
            <li>Chrome: Settings -- Advanced -- Content Settings -- Cookies</li>
            <br />
            <li>Safari: Preferences -- Privacy -- Cookies and website data</li>
            <br />
            <li>Firefox: Preferences -- Privacy -- History</li>
            <br />
            <li>Internet Explorer: Internet Options -- Privacy</li>
            <br />
            <li>Opera: Settings -- Privacy & security -- Cookies</li>
            <br />
          </ul>

          <p>Last updated on: 28th March 2020</p>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style scoped lang="scss">
//importing stylesheet for the booking policy page
@import "/src/styles/views/_booking-policy.scss";
</style>
